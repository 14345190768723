import { useLocale } from "@lib/tools/helpers";
import { usePathname } from "next/navigation";
import Head from "next/head";
import { memo } from "react";
const defaultImage = "https://cdn.rainbet.com/brand/og-banner.webp";
const isDefaultImage = (image: string) => image === defaultImage;
type Meta = {
  title?: string;
  description?: string;
  image?: string;
};
type MetaContentProps = Readonly<{
  meta: Meta & {
    url?: string;
    meta?: Meta;
  };
}>;
function MetaContent(props: MetaContentProps) {
  const {
    meta
  } = props;
  const l = useLocale();
  const pathname = usePathname();
  const baseUrl = pathname.split("?")[0];
  const locale = l === "en" ? "" : `/${l}`;
  const host = process.env.NEXT_PUBLIC_LIVE_URL + locale;
  const title = meta?.meta?.title || meta?.title || "Rainbet";
  const description = meta?.description || meta?.meta?.description || "Play casino game modes such as Slots, Blackjack, Baccarat, Roulette, Game Shows, Live Casino games and original RainBet games.";
  const image = meta?.image || meta?.meta?.image || defaultImage;
  const canonicalLink = meta?.url ? `${meta?.url}` : `${host}${baseUrl}`;
  return <Head data-sentry-element="Head" data-sentry-component="MetaContent" data-sentry-source-file="MetaContent.tsx">
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      <meta property="og:title" content={title} data-sentry-element="meta" data-sentry-source-file="MetaContent.tsx" />
      {description && <meta property="og:description" content={description} />}
      {canonicalLink && <link rel="canonical" href={canonicalLink} />}
      <meta property="og:site_name" content="Rainbet" data-sentry-element="meta" data-sentry-source-file="MetaContent.tsx" />
      <meta property="og:type" content="website" data-sentry-element="meta" data-sentry-source-file="MetaContent.tsx" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" data-sentry-element="meta" data-sentry-source-file="MetaContent.tsx" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover" data-sentry-element="meta" data-sentry-source-file="MetaContent.tsx" />

      {isDefaultImage(image) && <>
          <meta property="og:image:type" content="image/webp" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </>}
      <meta property="og:image" content={image} data-sentry-element="meta" data-sentry-source-file="MetaContent.tsx" />
    </Head>;
}
export default memo(MetaContent);