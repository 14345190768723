import Image from "next/image";
import st from "@styles/components/BlogCard.module.scss";
import useFormatDate from "@hooks/useFormatDate";
import useMobile from "@hooks/useMobile";
import MetaContent from "@items/MetaContent";
import { useState, useEffect, useMemo } from "react";
import useLanguage from "@hooks/useLanguage";
import { usePathname } from "next/navigation";
import inBlogAtom from "@recoil/inBlog/atom";
import { useSetRecoilState } from "recoil";
import { classnames, useLocale } from "@lib/tools/helpers";
import Link from "next/link";
import { generateBreadcrumbs } from "@lib/tools/breadcrumbs";
const BreadCrumbArrow = <svg xmlns="http://www.w3.org/2000/svg" width="7" height="9" fill="none" viewBox="0 0 7 9">
    <path fill="#E8E5FF" fillOpacity="0.2" d="M6.066 4.5a.837.837 0 01-.22.565L2.159 8.778A.757.757 0 011.598 9a.757.757 0 01-.561-.222.768.768 0 01-.22-.565c0-.229.073-.417.22-.565L4.163 4.5 1.037 1.352a.768.768 0 01-.22-.565c0-.229.073-.417.22-.565A.757.757 0 011.597 0c.228 0 .415.074.562.222l3.687 3.713c.08.08.137.168.17.262.034.094.05.195.05.303z"></path>
  </svg>;
export const modifyContent = document => {
  const h2s = [...document.querySelectorAll("h2")];
  const extractedHeadings = h2s?.map(h2 => {
    const id = h2.textContent.toLowerCase().replace(/[^a-z0-9]+/g, "-").replace(/(^-|-$)/g, "");
    h2.id = id;
    return {
      id,
      text: h2.textContent
    };
  });

  // document to string
  const serialized = document.body.innerHTML;
  return {
    serialized,
    extractedHeadings
  }; // Assuming you have a state [modifiedContent, setModifiedContent]
};
export default function BlogPage({
  posts,
  nometa,
  nonav,
  wide,
  headings = []
}) {
  const {
    formatDate
  } = useFormatDate();
  const isMobile = useMobile({
    breakPointThree: 600
  });
  const L = useLanguage("common");
  const pathname = usePathname();
  const setInBlog = useSetRecoilState(inBlogAtom);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const __html = posts?.content;
  const locale = useLocale();
  const breadcrumbs = useMemo(() => {
    if (nonav) return null;
    return generateBreadcrumbs(pathname, locale, "Blog").map((b, index, arr) => {
      return <Link key={b.href} href={b.href} data-breadcrumb className={st["anchor"]}>
            {b.title}
            {index < arr.length - 1 ? BreadCrumbArrow : ""}
          </Link>;
    });
  }, [pathname, locale, nonav]);
  useEffect(() => {
    setInBlog(true);
    return () => {
      setInBlog(false);
    };
  }, [setInBlog]);
  return <>
      {!nometa && <MetaContent meta={posts} />}
      <div {...classnames(st, "blog-page-container", wide && "blog-page-container__wide", isMobile)}>
        {!nonav && <div className={st["breadcrumbs"]}>{breadcrumbs}</div>}

        {posts.thumbnail && <div className={st["blog-page-image"]}>
            <div>
              <Image src={posts.thumbnail} alt="thumbnail" fill quality={90} sizes="900px" style={{
            objectFit: "cover"
          }} />
            </div>
          </div>}

        {posts.title && <div className={st["blog-page-title"]}>
            <h1>{posts.title}</h1>
            <div className={st["meta-info"]}>
              <div className={st["section"]}>
                <span className={st["item-name"]}>{L("by")}</span>{" "}
                <span>Rainbet</span>
              </div>
              <div className={st["section"]}>
                <span className={st["item-name"]}>{L("published")}</span>{" "}
                <span>{formatDate(posts.created_at)}</span>
              </div>
            </div>
          </div>}

        <div className={st["main-content-section"]}>
          {!nonav && headings.length ? <div {...classnames(st, "side-nav-container"
        // showMobileNav && "show",
        // footerHide && "hide"
        )}>
              <button {...classnames(st, "mobile-table-toggle", isDropdownOpen && "active")} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                <span>Table of Contents</span>
                <div className={st["arrow"]}>{BreadCrumbArrow}</div>
              </button>
              <div className={st["side-nav"]}>
                {headings.map(heading => <a key={heading.id} onClick={() => {
              setIsDropdownOpen(false);
            }} className={st["anchor"]} href={`#${heading.id}`}>
                    <span> {heading.text}</span>
                  </a>)}
              </div>
            </div> : null}

          <div className={st["main-content"]} dangerouslySetInnerHTML={{
          __html
        }} />
        </div>
      </div>
    </>;
}
export const Content = ({
  __html
}) => {
  return <div className={`${st["blog-page-container"]}`} style={{
    width: "100%",
    maxWidth: "100%"
  }} data-sentry-component="Content" data-sentry-source-file="index.jsx">
      <div className={st["main-content-section"]} style={{
      width: "100%"
    }}>
        <div className={st["main-content"]} style={{
        width: "100%"
      }} dangerouslySetInnerHTML={{
        __html
      }} />
      </div>
    </div>;
};